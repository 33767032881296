import ContentLoader from "react-content-loader";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 13px;
  // background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 7px;
  overflow: overlay;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Box = styled.div`
  background-color: white;
  padding: 7px;
  border-radius: 5px;
  position: relative;
`;

//---------------------------------------------------------

const FakeText1 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 50%;
  height: 20px;
`;

const FakeBox1 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: 50px;
`;

const FakeIconBox1 = styled.div`
  background-color: #d2d2d2f8;
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 22.5px;
  left: 22.5px;
  width: 15%;
  height: 35px;
`;

//---------------------------------------------------------

const FakeText2 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 38%;
  height: 20px;
`;

const FakeBox21 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 145px;
  left: 15px;
  width: calc(100% - 30px);
  height: 175px;
`;

const FakeBox22 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: 120px;
`;

const FakeIconBox21 = styled.div`
  background-color: #d2d2d2f8;
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 152.5px;
  left: 22.5px;
  width: 15%;
  height: 160px;
`;

const FakeIconBox22 = styled.div`
  background-color: #d2d2d2f8;
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 22.5px;
  left: 22.5px;
  width: 15%;
  height: 65px;
`;

//---------------------------------------------------------

const FakeText3 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 45%;
  height: 20px;
`;

const FakeBox31 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 125px;
  left: 15px;
  width: calc(100% - 30px);
  height: 95px;
`;

const FakeBox32 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: 100px;
`;

const FakeIconBox31 = styled.div`
  background-color: #d2d2d2f8;
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 132.5px;
  left: 22.5px;
  width: 15%;
  height: 80px;
`;

const FakeIconBox32 = styled.div`
  background-color: #d2d2d2f8;
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 22.5px;
  left: 22.5px;
  width: 15%;
  height: 85px;
`;

//---------------------------------------------------------

const FakeText4 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 40%;
  height: 20px;
`;

const FakeBox4 = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: 55px;
`;

const FakeIconBox4 = styled.div`
  background-color: #d2d2d2f8;
  padding: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 22.5px;
  left: 22.5px;
  width: 15%;
  height: 40px;
`;

//---------------------------------------------------------

const LoaderSkeleton = () => (
  <Container>
    <Box>
      <ContentLoader
        speed={1}
        width={"100%"}
        height={100}
        backgroundColor="lightgrey"
        foregroundColor="white"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
      <FakeText1 />
      <FakeBox1 />
      <FakeIconBox1 />
    </Box>
    <Box>
      <ContentLoader
        speed={1}
        width={"100%"}
        height={350}
        backgroundColor="lightgrey"
        foregroundColor="white"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
      <FakeText2 />
      <FakeBox21 />
      <FakeBox22 />
      <FakeIconBox21 />
      <FakeIconBox22 />
    </Box>
    <Box>
      <ContentLoader
        speed={1}
        width={"100%"}
        height={250}
        backgroundColor="lightgrey"
        foregroundColor="white"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
      <FakeText3 />
      <FakeBox31 />
      <FakeBox32 />
      <FakeIconBox31 />
      <FakeIconBox32 />
    </Box>
    <Box>
      <ContentLoader
        speed={1}
        width={"100%"}
        height={100}
        backgroundColor="lightgrey"
        foregroundColor="white"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
      <FakeText4 />
      <FakeBox4 />
      <FakeIconBox4 />
    </Box>
    <Box>
      <ContentLoader
        speed={1}
        width={"100%"}
        height={350}
        backgroundColor="lightgrey"
        foregroundColor="white"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
      <FakeText2 />
      <FakeBox21 />
      <FakeBox22 />
      <FakeIconBox21 />
      <FakeIconBox22 />
    </Box>
  </Container>
);
export default LoaderSkeleton;
