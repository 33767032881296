import styled from "styled-components";
import logo from "../../../src/logo.png";
import logo_text from "../../../src/gg_logo_only_text.png";
import your_booking_portal_img from "../../../src/your_booking_portal_img.png";
import { motion, AnimatePresence } from "framer-motion";

//---------------------------------------------------------

const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px 10px 12px;
  max-width: 500px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Logo = styled.img`
  height: 31px;
  object-fit: fill;
  animation: poutsa 30s linear infinite; //bokun script overrides styles when keyframe is named "spin"
  @keyframes poutsa {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LogoText = styled.img`
  height: 22px;
  object-fit: contain;
`;

const YourBookingPortalImg = styled.img`
  height: 30px;
  object-fit: contain;
`;

const item_variants = {
  hidden: {
    opacity: 0,
    y: -30,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -10,
  },
};

//---------------------------------------------------------

const Header: React.FC = () => {
  return (
    <AnimatePresence>
      <Container
        variants={item_variants}
        initial="hidden"
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.4,
          },
        }}
      >
        <ContentContainer>
          <LogoContainer>
            <Logo src={logo} />
            <LogoText src={logo_text} />
          </LogoContainer>
          <YourBookingPortalImg src={your_booking_portal_img} />
        </ContentContainer>
      </Container>
    </AnimatePresence>
  );
};

export default Header;
