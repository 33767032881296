import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Booking } from "../../screens/HomeScreen"
import MapInfo from "../map/MapInfo"
import TourInfo from "../tour_info/TourInfo"
import ActivityButton from "./ActivityButton"

//---------------------------------------------------------

const TourContainer = ({
  booking,
  length,
  index,
}: {
  booking: Booking
  length: number
  index: number
}) => {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <>
      {length > 1 && (
        <ActivityButton
          text={`Activity #${index} of ${length} - ${booking?.product.platform_product_name}`}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {isOpen && (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: -10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.3,
            },
          }}
        >
          <TourInfo booking={booking as Booking} />
          <MapInfo booking={booking as Booking} />
        </motion.div>
      )}
    </>
  )
}

export default TourContainer
