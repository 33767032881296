import {
  SectionContainer,
  SectionTitleContainer,
  SectionContentContainer,
  SectionContentItemContainer,
  SectionContentIconContainer,
  SectionContentTextContainer,
  ConfirmButton,
} from "../styled";
import { addActionToDb } from "../../api";
import { Booking } from "../../screens/HomeScreen";
import { useSearchParam } from "react-use";
import trip_advisor_img from "./tripadvisor.png";

//---------------------------------------------------------

const Review = ({ booking }: { booking: Booking }) => {
  const ref = useSearchParam("tour");
  return (
    <SectionContainer>
      <SectionTitleContainer>Leave us a review !</SectionTitleContainer>
      <SectionContentContainer>
        <SectionContentItemContainer>
          <SectionContentIconContainer>
            <img
              src={trip_advisor_img}
              style={{ width: "32%" }}
              alt="trip advisor icon"
            />
          </SectionContentIconContainer>
          <SectionContentTextContainer>
            Write an honest review about your experience with Getaways Greece.
            We would love to hear your feedback
          </SectionContentTextContainer>
        </SectionContentItemContainer>
        <SectionContentItemContainer>
          <ConfirmButton
            style={{
              backgroundColor: "#76b8f8",
              fontWeight: "bold",
              gap: "10px",
            }}
            onClick={() => {
              addActionToDb(ref as string, "VISIT_REVIEW_LINK");
              window.open(booking.product.review_link, "_blank");
            }}
          >
            <span>REVIEW US ON TRIPADVISOR</span>
            <i className="fa fa-tripadvisor"></i>
          </ConfirmButton>
        </SectionContentItemContainer>
      </SectionContentContainer>
    </SectionContainer>
  );
};

export default Review;
