import { useState, useEffect } from "react"
import styled from "styled-components"
import {
  motion,
  //AnimatePresence
} from "framer-motion"
// import LoaderSkeleton from "../components/LoaderSkeleton";
import { useNavigate, useLocation } from "react-router-dom"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import {
  SectionContentContainer,
  SectionContentItemContainer,
  SectionContentIconContainer,
  SectionContentTextContainer,
} from "../components/styled"
import { addActionToDb } from "../api"

//import RegiondoCatalogWidget from "../components/promo/RegiondoCatalogWidget";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  //flex-direction: column;
  background-color: white;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 7px 4px;
  justify-content: center;
  overflow: hidden;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 500px;
  height: 100%;
`

// const Iframe = styled.iframe`
//   width: 100%;
//   height: 100%;
//   border: none;
//   overflow: hidden;
//   z-index: 1;
// `;

const BackButtonContainer = styled.div`
  width: 100%;
  //background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
`

// const SpinnerContainer = styled(motion.div)`
//   width: 100%;
//   height: 100%;
// `;

type CustomHTML = {
  isDropdownOpen: boolean
}

const ArrowContainer = styled.i<CustomHTML>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 100%;
  font-size: 14px;
  transform: ${({ isDropdownOpen }) =>
    isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
  transition: transform 0.4s ease-in-out;
`

const BokunContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 0px 10px;
`

//----------------------------------------------------------------

const ShopScreen = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const ref = searchParams.get("tour")

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        addActionToDb(ref as string, "PROMO_PAGE_VISIT")
      }
    }

    // Invoke the visibility change handler immediately
    handleVisibilityChange()
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [ref])

  return (
    <Container>
      <ContentWrapper>
        <ContentContainer>
          <BackButtonContainer onClick={() => navigate(`/?tour=${ref}`)}>
            <i className="fa-solid fa-chevron-left"></i>
            <span>Back to portal</span>
          </BackButtonContainer>
          <SectionContentContainer
            style={{
              position: "relative",
            }}
          >
            <SectionContentItemContainer>
              <SectionContentIconContainer>
                <i className="fa-solid fa-cart-shopping"></i>
              </SectionContentIconContainer>
              <SectionContentTextContainer>
                <div>
                  Use the promo code :{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "darkgreen",
                    }}
                  >
                    PROMO_APP
                  </span>{" "}
                  during the checkout process to get a{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    15%
                  </span>{" "}
                  discount on your next booking
                </div>
              </SectionContentTextContainer>
            </SectionContentItemContainer>

            <ClickAwayListener
              onClickAway={() => {
                setIsDropdownOpen(false)
              }}
            >
              <div>
                <SectionContentItemContainer
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <SectionContentIconContainer>
                    <i className="fa-solid fa-circle-info"></i>
                  </SectionContentIconContainer>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <SectionContentTextContainer>
                      Redeeming coupon code instructions
                    </SectionContentTextContainer>
                    <ArrowContainer
                      isDropdownOpen={isDropdownOpen}
                      className={`fa-solid fa-angle-down`}
                    ></ArrowContainer>
                  </div>
                </SectionContentItemContainer>
                {isDropdownOpen && (
                  <ul
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "white",
                      zIndex: 2,
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                      borderRadius: 5,
                      fontSize: 12,
                      //paddingTop: 15,
                      paddingRight: 15,
                      paddingBottom: 10,
                      //listStyle: "none",
                    }}
                  >
                    <motion.li
                      key={"promo-instructions-1"}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.2,
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <li>Browse through the variety of guided tours below</li>
                    </motion.li>
                    <motion.li
                      key={"promo-instructions-1"}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.24,
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <li>
                        Add one or more tours to your cart after selecting
                        preferred date,time and participants.
                      </li>
                    </motion.li>
                    <motion.li
                      key={"promo-instructions-2"}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.28,
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <li>
                        When you have finished selecting your tours, click on
                        the "Checkout" button to move on to the payment page.
                      </li>
                    </motion.li>
                    <motion.li
                      key={"promo-instructions-3"}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.32,
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <li>
                        On the payment page,and after adding the traveller's
                        information, locate the input field labeled{" "}
                        <b>"Promo code"</b>
                      </li>
                    </motion.li>
                    <motion.li
                      key={"promo-instructions-4"}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.36,
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <li>
                        Enter your coupon code in the input field and click
                        apply.
                      </li>
                    </motion.li>
                    <motion.li
                      key={"promo-instructions-5"}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.4,
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <li>
                        The system will automatically apply a 15% discount on
                        the value of each ordered product once the coupon code
                        is successfully validated,and you can proceed to
                        complete your order.
                      </li>
                    </motion.li>
                  </ul>
                )}
              </div>
            </ClickAwayListener>
          </SectionContentContainer>

          {/* REGIONDO WIDGET */}
          {/* <Iframe
            src={"https://getawaysgreece.com/shop/"}
            title="getawaysgreece.com"
            style={{
              width: "100%",
              height: "100%",
              display: isLoading ? "none" : "block",
            }}
            onLoad={() => setIsLoading(false)}
          /> */}

          <BokunContainer
            className="bokunWidget"
            data-src="https://widgets.bokun.io/online-sales/db6fd107-983c-4e5e-8d51-b37b123ddd0d/product-list/68121"
          />

          {/* <BokunContainer
            className="bokunWidget"
            data-src="https://widgets.bokun.io/online-sales/db6fd107-983c-4e5e-8d51-b37b123ddd0d/experience/774340"
          /> */}

          {/* BOKUN WIDGET HAS ITS OWN SKELETON LOADER */}
          {/* {isLoading && (
            <AnimatePresence>
              <SpinnerContainer
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <LoaderSkeleton />
              </SpinnerContainer>
            </AnimatePresence>
          )} */}
        </ContentContainer>
      </ContentWrapper>
    </Container>
  )
}

export default ShopScreen
