import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //position: relative;
  background-color: #4fc04f;
  border-radius: 100%;
  padding: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  width: 20px;
  height: 20px;
  i {
    font-size: 12px;
    color: white;
  }
`;

// const Text = styled.div`
//   position: absolute;
//   top: -30px;
//   font-size: 15px;
//   color: white;
//   text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
// `;

//---------------------------------------------------------

export const ClientMarker = () => {
  return (
    <Container>
      <i className="fa-solid fa-flag"></i>
      {/* <Text>ITY-2683</Text> */}
    </Container>
  );
};
