import { useEffect } from "react"
import GlobalStyles from "./globalStyles"
import { ThemeProvider } from "styled-components"
import { ReactQueryDevtools } from "react-query/devtools"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { theme } from "./theme"
import HomeScreen from "./screens/HomeScreen"
import ShopScreen from "./screens/ShopScreen"
import useTawkTo from "./useTawkTo"
import { openSessionToDb, closeSessionToDb } from "./api"
import {
  isMobile,
  isTablet,
  isDesktop,
  isBrowser,
  osName,
  osVersion,
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
} from "react-device-detect"
import { useSearchParam } from "react-use"
const queryClient = new QueryClient()

//----------------------------------------------------------------

function App() {
  const ref = useSearchParam("tour")

  // useTawkTo();

  //create a variable "platform" that will loop isMobile, isTablet, isDesktop, isBrowser and return the property name of the true value in string without the "is" prefix
  const platform = Object.keys({
    isMobile,
    isTablet,
    isDesktop,
    isBrowser,
  })
    .filter((key) => {
      return {
        isMobile,
        isTablet,
        isDesktop,
        isBrowser,
      }[key]
    })[0]
    .replace("is", "")

  useEffect(() => {
    const handleVisibilityChange = () => {
      //console.log(document.visibilityState, ref);
      if (document.visibilityState === "visible") {
        if (ref) {
          openSessionToDb(ref, {
            platform,
            osName,
            osVersion,
            browserName,
            browserVersion,
            mobileVendor,
            mobileModel,
          })
        }
      } else {
        closeSessionToDb(ref as string)
      }
    }
    const handlePageHide = () => {
      closeSessionToDb(ref as string)
    }

    const handleBeforeUnload = () => {
      closeSessionToDb(ref as string)
    }

    const handleUnload = () => {
      closeSessionToDb(ref as string)
    }

    // Invoke the visibility change handler immediately is needed to trigger if (document.visibilityState === "visible") on page load
    handleVisibilityChange()
    document.addEventListener("visibilitychange", handleVisibilityChange)
    document.addEventListener("pagehide", handlePageHide)
    document.addEventListener("beforeunload", handleBeforeUnload)
    document.addEventListener("unload", handleUnload)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
      document.removeEventListener("pagehide", handlePageHide)
      document.removeEventListener("beforeunload", handleBeforeUnload)
      document.removeEventListener("unload", handleUnload)
    }
  }, [ref, platform])

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/shop" element={<ShopScreen />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
