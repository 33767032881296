import {
  SectionContainer,
  SectionTitleContainer,
  SectionContentContainer,
  SectionContentItemContainer,
  SectionContentIconContainer,
  SectionContentTextContainer,
  ConfirmButton,
} from "../styled";
import { addActionToDb } from "../../api";
import { useSearchParam } from "react-use";

//---------------------------------------------------------

const Sim = () => {
  const ref = useSearchParam("tour");
  return (
    <SectionContainer>
      <SectionTitleContainer>
        Looking for an e-Sim phone number for your trip ?
      </SectionTitleContainer>
      <SectionContentContainer>
        <SectionContentItemContainer>
          <SectionContentIconContainer>
            <i className="fa-solid fa-sim-card"></i>
          </SectionContentIconContainer>
          <SectionContentTextContainer>
            Buy an international virtual eSIM card with unlimited data, stay
            connected wherever you go, and avoid expensive phone bills on your
            trip
          </SectionContentTextContainer>
        </SectionContentItemContainer>
        <SectionContentItemContainer>
          <ConfirmButton
            style={{
              backgroundColor: "#76b8f8",
              fontWeight: "bold",
            }}
            onClick={() => {
              addActionToDb(ref as string, "VISIT_SIM_LINK");
              window.open("https://connectphone.eu/download-app", "_blank");
            }}
          >
            GET YOUR eSIM NOW
          </ConfirmButton>
        </SectionContentItemContainer>
      </SectionContentContainer>
    </SectionContainer>
  );
};

export default Sim;
