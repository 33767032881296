import { Booking } from "../../screens/HomeScreen"
import { useGetPhoneNumberOnDuty } from "../../reactQueryHooks"
import styled from "styled-components"
import {
  SectionContainer,
  SectionTitleContainer,
  SectionContentContainer,
  SectionContentItemContainer,
  SectionContentIconContainer,
  SectionContentTextContainer,
} from "../styled"

//---------------------------------------------------------

const PlatformsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const PlatformContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
`

const PlatformTextContainer = styled.div`
  font-size: 12px;
`

//---------------------------------------------------------

const Contact = ({ booking }: { booking: Booking }) => {
  const { mutateAsync: getPhoneNumberOnDuty } = useGetPhoneNumberOnDuty()

  return (
    <SectionContainer>
      <SectionTitleContainer>Have questions ?</SectionTitleContainer>
      <SectionContentContainer>
        <SectionContentItemContainer>
          <SectionContentIconContainer>
            <i className="fa-solid fa-comments"></i>
          </SectionContentIconContainer>
          <SectionContentTextContainer>
            Send us a message using one of the platforms below
          </SectionContentTextContainer>
        </SectionContentItemContainer>
      </SectionContentContainer>
      <PlatformsContainer>
        <PlatformContainer
          onClick={async () => {
            try {
              const { phone_number } = await getPhoneNumberOnDuty()

              const encodedEmailText = `
            [${booking.ref}/${booking.client_name}]
            --- YOUR MESSAGE BELOW ---
          `
                .split("\n")
                .map((line) => encodeURIComponent(line))
                .join("%0A")
              window.open(
                `sms:${phone_number}&body=${encodedEmailText}`,
                "_blank"
              )
            } catch (e: any) {
              alert(e.response.data)
            }
          }}
        >
          <i className="fa-brands fa-apple"></i>
          <PlatformTextContainer>iMessage</PlatformTextContainer>
        </PlatformContainer>

        <PlatformContainer
          onClick={async () => {
            try {
              const { phone_number } = await getPhoneNumberOnDuty()

              const encodedEmailText = `
                [${booking.ref}/${booking.client_name}]
                --- YOUR MESSAGE BELOW ---
              `
                .split("\n")
                .map((line) => encodeURIComponent(line))
                .join("%0A")
              window.open(
                `whatsapp://send?&phone=${phone_number}&abid=${phone_number}&text=${encodedEmailText}`,
                "_blank"
              )
            } catch (e: any) {
              alert(e.response.data)
            }
          }}
        >
          <i
            style={{
              color: "#23d366",
            }}
            className="fa-brands fa-whatsapp"
          ></i>
          <PlatformTextContainer>Whatsapp</PlatformTextContainer>
        </PlatformContainer>

        <PlatformContainer
          onClick={async () => {
            try {
              const { phone_number } = await getPhoneNumberOnDuty()
              const formattedPhoneNumber = phone_number.replace("+", "")

              const encodedEmailText = `
            [${booking.ref}/${booking.client_name}]
            --- YOUR MESSAGE BELOW ---
          `
                .split("\n")
                .map((line) => encodeURIComponent(line))
                .join("%0A")
              window.open(
                `viber://chat?number=%2B${formattedPhoneNumber}&draft=${encodedEmailText}`,
                "_blank"
              )
            } catch (e: any) {
              alert(e.response.data)
            }
          }}
        >
          <i
            style={{
              color: "#785cef",
            }}
            className="fa-brands fa-viber"
          ></i>
          <PlatformTextContainer>Viber</PlatformTextContainer>
        </PlatformContainer>
        <PlatformContainer
          onClick={() => {
            window.open(
              `mailto:operations@getawaysgreece.com?subject=${`[${booking.ref}/${booking.client_name}]`}`,
              "_blank"
            )
          }}
        >
          <i className="fa-regular fa-envelope"></i>
          <PlatformTextContainer>Email</PlatformTextContainer>
        </PlatformContainer>
      </PlatformsContainer>
    </SectionContainer>
  )
}

export default Contact
