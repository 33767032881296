import { useState, useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { addActionToDb } from "../api"
import { useGetBookings } from "../reactQueryHooks"
import { motion, AnimatePresence } from "framer-motion"
import Header from "../components/header/Header"
import Weather2 from "../components/weather/Weather"
import LoaderSkeleton from "../components/LoaderSkeleton"
import TourContainer from "../components/tours/TourContainer"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import Review from "../components/review/Review"
import Promo from "../components/promo/Promo"
import Contact from "../components/contact/Contact"
import Sim from "../components/sim/Sim"
import { useSearchParam } from "react-use"

// import Ferry from "../components/ferry/Ferry";
// import { isMobile } from "react-device-detect";
// import AddLocation from "../components/add_location/AddLocation";
//import busLoadingAnimation from "../lottie_bus_loader.json";
//import Lottie from "lottie-react";
// import logo from "./logo.png";

//-----------------------------------------------------

const Container = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightblue};
  overflow: hidden;
`

const ContentWrapper = styled(motion.div)`
  flex: 1;
  display: flex;
  padding: 5px;
  justify-content: center;
  overflow: hidden;
  position: relative;
`

const ContentContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
`

const ContentContainerWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-top: 5px;
`

const SpinnerContainer = styled(motion.div)`
  width: 100%;
  max-width: 500px;
  height: 100%;
`

const ItemContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  //padding: 5px 5px 0 0;
  padding-top: 5px;
  //box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
`

const InvalidIdContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: indianred;
  span {
    font-size: 14px;
  }
  i {
    font-size: 20px;
  }
`

const BookingInputContainerOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const BookingInputContainer = styled(motion.div)`
  border-radius: 10px;
  padding: 7px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  z-index: 2;
`

const BookingInput = styled.input`
  border: none;
  border-radius: 2px;
  padding: 7px;
  font-size: 20px;
  color: #dcdcdc;
  background: transparent;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  ::placeholder {
    color: #bdbdbd;
  }
`

const SubmitButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 7px;
  font-size: 20px;
  color: white;
  background: #5a975a;
  outline: none;
  text-align: center;
  text-transform: uppercase;
`

const OffersButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 5px 7px;
  font-size: 12px;
  color: white;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  &:hover {
    background: #6e6e6e;
    transition: 0.3s;
  }
  i {
    font-size: 12px;
  }
`

const spinner_variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const item_variants = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -10,
  },
}

//---------------------------------------------------------

export interface Booking {
  _id: string
  ref: string
  isFirstVisit: boolean
  client_name: string
  tickets: Object
  product: {
    platform_product_name: string
    product_images: Array<string>
    additional_info: Array<string>
    special_instructions: Array<string>
    highlights: Array<string>
    product_short_description: string
    pickupIncluded: boolean
    coordinates: {
      lat: number
      lng: number
    }
    location: {
      address: string
      latitude: number
      longitude: number
    }
    review_link: string
  }
  date: string
  client_response_status: string
  client_location: string
  pickup_location: {
    name: string
    latitude: number
    longitude: number
    google_maps_url: string
    instructions: string
    img_url: string
    address: string
  }
  pickup_time: string
  task: {
    _id: string
    assignees: [
      {
        name: string
      }
    ]
    vehicle: {
      plate: string
      type: string
      color: string
    }
  }
}

export interface BookingContainer {
  booking: Booking
  task: Booking["task"]
}

function updateUrlWithQueryParam(paramName: string, paramValue: string): void {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(paramName, paramValue)
  const newUrl = `${window.location.pathname}?${searchParams.toString()}`
  window.location.href = newUrl
}

//---------------------------------------------------------

const HomeScreen = () => {
  const navigate = useNavigate()
  const [paramValue, setParamValue] = useState("")
  const ref = useSearchParam("tour")
  const hasConfirmPrompt = useSearchParam("confirm_prompt")

  useEffect(() => {
    if (typeof hasConfirmPrompt !== "object") {
      document.title = `Confirm your booking`
    }
  }, [hasConfirmPrompt])

  const [targetRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px -200px 0px",
  })

  const { data, isFetching, isError, error } = useGetBookings(
    ref ? ref?.toUpperCase() : undefined
  ) //if ref is undefined,it will not fetch
  console.log("data", data)
  //const hasTask = data?.task?._id;

  const hasData = data !== undefined

  const errorMsg = (error as any)?.response?.data

  useEffect(() => {
    //reached the bottom of the page
    //inView is true when the element (last card) is in view
    if (inView) {
      //if user refreshes the page,it will trigger again in case the user scrolls again to the bottom
      addActionToDb(ref as string, "SCROLLED_TO_BOTTOM")
    }
  }, [ref, inView])

  useEffect(() => {
    if (data && data[0].booking.isFirstVisit) {
      alert(`Welcome to your Getaways Greece booking portal!

  You are now eligible to receive a 15% discount on your next booking with us, click on "View Offers" below to see our other tours
        `)
    }
  }, [data])

  //updateUrlWithQueryParam("tour", paramValue.toUpperCase())
  //on enter key press

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        updateUrlWithQueryParam("tour", paramValue.toUpperCase())
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [paramValue])

  return (
    <Container>
      <Header />
      <ContentWrapper>
        {isFetching && (
          <AnimatePresence>
            <SpinnerContainer
              variants={spinner_variants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <LoaderSkeleton />
            </SpinnerContainer>
          </AnimatePresence>
        )}
        {!isFetching && isError && ref && (
          <InvalidIdContainer
            variants={item_variants}
            initial="hidden"
            animate={{
              opacity: 1,
              transition: {
                delay: 0.1,
              },
            }}
          >
            {errorMsg}
            <Button
              variant="contained"
              onClick={() => {
                updateUrlWithQueryParam("tour", "")
              }}
              style={{
                width: "200px",
              }}
            >
              Retry
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={() => navigate(`/shop?tour=${ref}`)}
              style={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              SEE OFFERS
              <i
                style={{
                  fontSize: "12px",
                }}
                className="fa-solid fa-up-right-from-square"
              ></i>
            </Button>
          </InvalidIdContainer>
        )}

        {!isFetching && !isError && !ref && (
          <BookingInputContainerOverlay>
            <BookingInputContainer>
              <BookingInput
                placeholder="insert booking #"
                value={paramValue}
                onChange={(e: any) => setParamValue(e.target.value)}
                autoFocus
              />
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  updateUrlWithQueryParam("tour", paramValue.toUpperCase())
                }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/shop?tour=${ref}`)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                SEE OFFERS
                <i className="fa-solid fa-up-right-from-square"></i>
              </Button>
            </BookingInputContainer>
          </BookingInputContainerOverlay>
        )}
        {!isFetching && !isError && hasData && ref && (
          <ContentContainer>
            <ContentContainerWrapper>
              <ItemContainer
                variants={item_variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                  },
                }}
              >
                {data?.map((booking: BookingContainer, index: number) => {
                  return (
                    <TourContainer
                      key={booking.booking._id}
                      booking={{
                        ...booking.booking,
                        task: booking.task,
                      }}
                      length={data.length}
                      index={index + 1}
                    />
                  )
                })}
              </ItemContainer>

              <ItemContainer
                variants={item_variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                  },
                }}
              >
                <Promo />
              </ItemContainer>

              <ItemContainer
                variants={item_variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                  },
                }}
              >
                <Sim />
              </ItemContainer>

              <ItemContainer
                variants={item_variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                  },
                }}
              >
                <Review booking={data[0].booking as Booking} />
              </ItemContainer>

              <ItemContainer
                variants={item_variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                  },
                }}
              >
                <Contact booking={data[0].booking as Booking} />
              </ItemContainer>

              <ItemContainer
                variants={item_variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                  },
                }}
                ref={targetRef}
              >
                <Weather2 booking={data[0].booking as Booking} />
              </ItemContainer>
            </ContentContainerWrapper>
          </ContentContainer>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default HomeScreen
