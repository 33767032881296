import styled from "styled-components"

//--------------------------------------------------------

const Button = styled.button`
  font-size: 12px;
  color: black;
  font-weight: bold;
  padding: 10px 10px 8px 10px;
  background-color: #fbe0ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 5px;
  border-bottom: 3px solid #93baf5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 600;
  gap: 10px;
  span {
    //wrap with ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

type CustomHTML = {
  isDropdownOpen: boolean
}

export const ArrowContainer = styled.i<CustomHTML>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
  transform: ${({ isDropdownOpen }: any) =>
    isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
  transition: transform 0.3s ease-in-out;
`

//--------------------------------------------------------

const ActivityButton = ({
  text,
  isOpen,
  setIsOpen,
}: {
  text: string
  isOpen: boolean
  setIsOpen: any
}) => {
  return (
    <Button onClick={() => setIsOpen((prev: boolean) => !prev)}>
      <span></span>
      <span>{text}</span>
      <ArrowContainer
        isDropdownOpen={isOpen}
        className={`fa-solid fa-angle-down`}
      ></ArrowContainer>
    </Button>
  )
}

export default ActivityButton
