import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`



body{
//font-family: 'Poppins', sans-serif;
//font-family: 'Zona Pro', sans-serif;
max-height: 100dvh;
min-height: 100dvh;
font-family: 'Roboto', sans-serif;
font-weight: 500;
letter-spacing: 0.1px;                                           
overflow-x: hidden;
margin: 0; //has default margin
background-color: ${(props) => props.theme.colors.lightblue};
display: flex;
}
#root{
  flex: 1;
  display: flex;
  overflow-x: hidden;
@media (max-width: 250px) {
  font-size: 10px;
}
}

button {
  all: unset;
  cursor: pointer;
  outline: none !important;
}

input { 
  outline: none !important;
  border: none ;
}
.react-datepicker-popper {
    z-index: 9999 !important;
}
.mapboxgl-popup {
  padding: 0;
  margin: 0;
  
}
.mapboxgl-popup-content {
  padding: 0;
  margin: 0;
   //box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
   //after 
}
/* .mapboxgl-popup-tip {
  background-color: red;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  &:after {
    background-color: red;
  }
  &:before {
    background-color: red;
  }
} */
.rw-container{
  //padding: 5px !important;

}
.rw-container-left {
  //background: red !important;
  padding: 10px 20px !important;
  background: whitesmoke !important;
  border-radius: 5px  0 0 5px !important;
 
}
.rw-container-right{
  background: #f0f0f0 !important;
  border-radius: 0 5px   5px 0 !important;

}
.rw-container-header{
  font-size: 20px !important;
}
.rw-today-hr{
  display: none;
}
.rw-today{
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px; */
}
.rw-today-info{
  display: none;
}
.rw-today-current{
  font-size: 18px !important;
}
.rw-today-desc{
  font-size: 12px !important;
}
.swal2-backdrop-show {
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.5) !important;
    transition: backdrop-filter 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(2vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
`;

export default GlobalStyles;
