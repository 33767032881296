import { useQuery, useMutation } from "react-query"

import {
  getBookings,
  getTrackingData,
  getSunset,
  addLocation,
  getPhoneNumberOnDuty,
} from "./api"

//-----------------------------------------------------------------------------------

export const useGetBookings = (ref: string | undefined) => {
  return useQuery(["BOOKINGS"], () => getBookings(ref), {
    //staleTime 5 minutes
    //staleTime: 1000 * 60 * 5,
    staleTime: 0,
    enabled: !!ref,
    refetchOnWindowFocus: false,
    retry: 0,
  })
}

export const useGetTrackingData = (
  booking_id: string | undefined,
  task_id: string | undefined,
  origin: string | undefined
) => {
  return useQuery(
    ["TRACKING_DATA", booking_id, task_id, origin],
    () => getTrackingData(booking_id, task_id),
    {
      refetchOnMount: false,
      enabled: !!booking_id,
      retry: 0,
    }
  )
}

export const useGetPhoneNumberOnDuty = () => {
  return useMutation(getPhoneNumberOnDuty, {})
}

export const useGetSunset = (lat: number, lng: number) => {
  return useQuery(["SUNSET", lat, lng], () => getSunset(lat, lng), {
    refetchOnMount: false,
  })
}

export const useAddLocation = () => {
  return useMutation(
    ({ booking_id, location }: { booking_id: string; location: string }) =>
      addLocation(booking_id, location),
    {
      onError: (error) => {
        console.log("useChangeLocation", error)
        alert("Error changing location")
      },
      retry: false,
    }
  )
}
