import { useNavigate, useLocation } from "react-router-dom";
import {
  SectionContainer,
  SectionTitleContainer,
  SectionContentContainer,
  SectionContentItemContainer,
  SectionContentIconContainer,
  SectionContentTextContainer,
  ConfirmButton,
} from "../styled";
//import RegiondoCatalogWidget from "./RegiondoCatalogWidget";

//---------------------------------------------------------

const Promo = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get("tour");

  return (
    <>
      <SectionContainer>
        <SectionTitleContainer>
          Book your next tour with Getaways !
        </SectionTitleContainer>
        <SectionContentContainer>
          <SectionContentItemContainer>
            <SectionContentIconContainer>
              <i className="fa-solid fa-cart-shopping"></i>
            </SectionContentIconContainer>
            <SectionContentTextContainer>
              <div>
                Use the promo code :{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "darkgreen",
                  }}
                >
                  PROMO_APP
                </span>{" "}
                during the checkout process to get a{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  15%
                </span>{" "}
                discount on your next booking
              </div>
            </SectionContentTextContainer>
          </SectionContentItemContainer>
          {/* <SectionContentItemContainer
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <SectionContentIconContainer>
              <i className="fa-solid fa-circle-info"></i>
            </SectionContentIconContainer>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SectionContentTextContainer>
                Redeeming promo code instructions
              </SectionContentTextContainer>
              <ArrowContainer
                isDropdownOpen={isDropdownOpen}
                className={`fa-solid fa-angle-down`}
              ></ArrowContainer>
            </div>
          </SectionContentItemContainer>
          {isDropdownOpen && (
            <ul>
              <motion.li
                key={"promo-instructions-1"}
                initial={{ opacity: 0, x: -10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.2,
                  },
                }}
                exit={{ opacity: 0, y: -10 }}
              >
                <li>Browse through the variety of guided tours below</li>
              </motion.li>
              <motion.li
                key={"promo-instructions-1"}
                initial={{ opacity: 0, x: -10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.2,
                  },
                }}
                exit={{ opacity: 0, y: -10 }}
              >
                <li>
                  Add one or more tours to your cart after selecting preferred
                  date,time and participants.
                </li>
              </motion.li>
              <motion.li
                key={"promo-instructions-2"}
                initial={{ opacity: 0, x: -10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.4,
                  },
                }}
                exit={{ opacity: 0, y: -10 }}
              >
                <li>
                  When you have finished selecting your tours, click on the
                  "Checkout" button to move on to the payment page.
                </li>
              </motion.li>
              <motion.li
                key={"promo-instructions-3"}
                initial={{ opacity: 0, x: -10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.6,
                  },
                }}
                exit={{ opacity: 0, y: -10 }}
              >
                <li>
                  On the payment page,and after adding the traveller's
                  information, locate the input field labeled{" "}
                  <b>"Promo code"</b>
                </li>
              </motion.li>
              <motion.li
                key={"promo-instructions-4"}
                initial={{ opacity: 0, x: -10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0, y: -10 }}
              >
                <li>
                  Enter your promo code in the input field and click apply.
                </li>
              </motion.li>
              <motion.li
                key={"promo-instructions-5"}
                initial={{ opacity: 0, x: -10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                exit={{ opacity: 0, y: -10 }}
              >
                <li>
                  The system will automatically apply a 15% discount on the
                  value of each ordered product once the coupon code is
                  successfully validated,and you can proceed to complete your
                  order.
                </li>
              </motion.li>
            </ul>
          )} */}
        </SectionContentContainer>
        {/* <SectionContentItemContainer
          style={{
            padding: "7px",
            borderRadius: "5px",
            backgroundColor: "white",
          }}
        >
          <RegiondoCatalogWidget />
        </SectionContentItemContainer> */}
        <SectionContentItemContainer>
          <ConfirmButton
            style={{
              backgroundColor: "#76b8f8",
              fontWeight: "bold",
            }}
            onClick={() => navigate(`/shop?tour=${ref}`)}
          >
            VIEW OFFERS
          </ConfirmButton>
        </SectionContentItemContainer>
      </SectionContainer>
    </>
  );
};
// Re-c-jss380 Re-c-jss381

export default Promo;
