import { useState, useEffect, useCallback } from "react"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router-dom"
import moment from "moment"
import Swal from "sweetalert2"
import BarLoader from "react-spinners/BarLoader"
import {
  SectionContainer,
  SectionTitleContainer,
  SectionContentContainer,
  SectionContentItemContainer,
  SectionContentIconContainer,
  SectionContentTextContainer,
  ConfirmButton,
} from "../styled"
import { Booking, BookingContainer } from "../../screens/HomeScreen"
import { confirmStatus } from "../../api"
import { useSearchParam } from "react-use"

//-----------------------------------------------------

const ConfirmStatus = ({ booking }: { booking: Booking }) => {
  const [isConfirming, setIsConfirming] = useState(false)
  //const queryClient = useQueryClient()

  const isConfirmed = booking.client_response_status === "CONFIRMED"
  const confirmedParam = useSearchParam("confirmed")

  const handleConfirm = useCallback(async () => {
    try {
      setIsConfirming(true)
      await confirmStatus(booking._id)

      //reload page
      window.location.reload()

      // const currentBookingData = queryClient.getQueryData([
      //   "BOOKINGS",
      // ]) as BookingContainer[]

      // console.log("currentBookingData", currentBookingData)

      // const updatedBookings = currentBookingData.map((b) => {
      //   if (b.booking._id === booking._id) {
      //     return {
      //       ...b,
      //       booking: {
      //         ...b.booking,
      //         client_response_status: "CONFIRMED",
      //       },
      //     }
      //   }
      //   return booking
      // })

      //console.log("updatedBookings", updatedBookings)
      //queryClient.setQueryData(["BOOKINGS"], updatedBookings)
      //console.log("Booking confirmed")
      setIsConfirming(false)
    } catch (e) {
      alert(e?.toString())
    }
  }, [booking])

  useEffect(() => {
    if (confirmedParam === "true" && !isConfirmed) {
      handleConfirm()
    }
  }, [confirmedParam, handleConfirm, isConfirmed])

  return (
    <SectionContainer>
      <SectionTitleContainer>
        Traveller confirmation status
      </SectionTitleContainer>
      <SectionContentContainer>
        <SectionContentItemContainer>
          <SectionContentIconContainer>
            <i className="fa-solid fa-thumbs-up"></i>
          </SectionContentIconContainer>
          <SectionContentTextContainer
            style={{
              color:
                booking?.client_response_status === "CONFIRMED"
                  ? "#6A914F"
                  : "indianred",
            }}
          >
            {isConfirmed ? (
              <span>
                You have confirmed
                <i
                  style={{
                    marginLeft: "5px",
                    fontSize: "20px",
                  }}
                >
                  👏
                </i>
              </span>
            ) : (
              "PENDING"
            )}
          </SectionContentTextContainer>
        </SectionContentItemContainer>
        {booking?.client_response_status === "PENDING" && (
          <SectionContentItemContainer>
            <ConfirmButton
              onClick={() => {
                //.swal2-backdrop-show styles added to globalStyles
                Swal.fire({
                  title: "Are you sure you want to confirm?",
                  //text: "Kindly scroll down and review the details of your booking, such as the meeting point and time carefully before confirming.",
                  html: "<span style='text-align:left'>Scroll down and review the details of your booking, such as the <b>meeting point and time</b> carefully before confirming.</span>",
                  // html: `
                  // <div style="text-align: left; font-size: 15px;">
                  //   <p>Kindly review the details of your booking, such as the meeting point and time carefully before confirming.</p>
                  //   <ul><li>Meeting Point: ${meetingPoint}</li></ul>
                  //   <ul><li>Meeting Time: ${meetingTime}</li></ul>
                  // </div>
                  // `,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "I have read the details, confirm!",
                  confirmButtonColor: "#6A914F",
                  cancelButtonText: "I'll have a look again",
                  cancelButtonColor: "rgb(202,106,106)",
                  //set backdrop class to .backdrop-swal2
                  backdrop: `
                    rgba(0,0,0,0.4)
                    url("/images/nybg.jpg")
                    center
                    no-repeat
                  `,

                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleConfirm()
                    //console.log("confirm");
                  }
                  // else if (result.dismiss === Swal.DismissReason.cancel) {
                  //   Swal.fire(
                  //     "Cancelled",
                  //     "Your booking is not confirmed :)",
                  //     "error"
                  //   );
                  // }
                })
              }}
            >
              {!isConfirming && "CLICK TO CONFIRM"}
              {isConfirming && <BarLoader color="#fff" loading={true} />}
            </ConfirmButton>
          </SectionContentItemContainer>
        )}
      </SectionContentContainer>
    </SectionContainer>
  )
}

export default ConfirmStatus
