import { Booking } from "../../screens/HomeScreen";
import styled from "styled-components";
import ReactWeather, { useOpenWeather } from "react-open-weather";
const api_key = process.env.REACT_APP_OPEN_WEATHER_API_KEY;

//---------------------------------------------------------

const WeatherContainer = styled.div`
  //targer .rw-container inside this div
`;

//---------------------------------------------------------

const Weather = ({ booking }: { booking: Booking }) => {
  const location = booking?.product.location;
  // const { data: sunset } = useGetSunset(
  //   booking?.product.coordinates.lat,
  //   booking?.product.coordinates.lng
  // );

  const { data, isLoading, errorMessage } = useOpenWeather({
    key: api_key,
    lat: location.latitude,
    lon: location.longitude,
    lang: "en",
    unit: "metric",
  });

  return (
    <WeatherContainer>
      <ReactWeather
        theme={{
          fontFamily: "Helvetica, sans-serif",
          gradientStart: "whitesmoke",
          gradientMid: "white",
          gradientEnd: "whitesmoke",
          locationFontColor: "black",
          todayTempFontColor: "#777",
          todayDateFontColor: "#777",
          todayRangeFontColor: "#777",
          todayDescFontColor: "#777",
          todayInfoFontColor: "#777",
          todayIconColor: "#4BC4F7",
          forecastBackgroundColor: "#FFF",
          forecastSeparatorColor: "#DDD",
          forecastDateColor: "#777",
          forecastDescColor: "#777",
          forecastRangeColor: "#777",
          forecastIconColor: "#4BC4F7",
        }}
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="en"
        locationLabel={location.address}
        unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
        showForecast
      />
    </WeatherContainer>
  );
};

export default Weather;
