import styled from "styled-components"

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  background-color: whitesmoke;
  padding: 7px;
  border-radius: 5px;
`

export const SectionTitleContainer = styled.div`
  color: dodgerblue;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.7px;
`

export const SectionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 7px;
  gap: 5px;
  border-radius: 5px;
  ul li::marker {
    //dot
    font-size: 20px;
    color: grey;
  }
`

export const SectionContentItemContainer = styled.div`
  display: flex;
  gap: 10px;
  min-height: 35px;
  //align-items: center;
`

export const SectionContentIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  min-width: 50px;
  max-width: 50px;
  min-height: 100%;
  border-radius: 5px;
  font-size: 12px;
`

export const SectionContentTextContainer = styled.div`
  font-size: 14px;
  min-height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const SectionContentInputContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-height: 45px;
  justify-content: flex-start;
  align-items: center;
`

export const SectionContentInput = styled.input`
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc !important;
  border: none;
  border-radius: 5px;
  padding: 0 5px;
  &::placeholder {
    font-size: 12px;
    color: #aeaeae;
    text-align: center;
  }
`

export const ConfirmButton = styled.button`
  flex: 1;
  height: 100%;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6a914f;
  border-radius: 5px;
  color: whitesmoke;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &hover {
    filter: brightness(0.9);
  }
`

export const CancelButton = styled.button`
  height: 100%;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightcoral;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  color: whitesmoke;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &hover {
    filter: brightness(0.9);
  }
`
